.infoLine {
    display: flex;
    justify-content: start;
    align-items: center;
    line-height: 2rem;
    gap: 1rem;
}

.stepItems {
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #495057;
    border-radius: 0.2rem;
}

.stepItems.active {
    color: #ffda6a;
    border: 1px solid #ffda6a;
}

.infoLine>div:first-child {
    font-weight: bold;
}