.detailBackButton{
    color: #fff;
    font-size: larger;
    margin-top: 4rem;
    text-align: center;
    margin-bottom: 1rem;
}
.detailBackButton span {
    cursor: pointer;
}


/* hover */
.detailBackButton:hover{
    color: #fff;
    font-size: larger;
    font-weight: bold;
}

.detailSocial {
    cursor: pointer;
}
.detailSocial:hover{
    color: #fff;
    font-weight: bold;
}
.detailSocialInfo {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 1rem;
    gap: 0.6rem;
}

.detailProjectInfo{
    margin-top: 1rem;
    background-color: rgb(46, 48, 58);
    padding: 0.8rem;
}
.detailProjectBody{
    display: flex;
    justify-content: start;
    align-items: start;
}
.detailProjectInfo img {
    width: 128px;
    height: 128px;
    border-radius: 5px;
    margin-right: 0.6rem;
}

.detailProjectInfoTitle {
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.25rem;
    display: flex;
    justify-content: start;
    align-items: center;
    color: rgb(148 163 184);
    font-size: .75rem;
    margin-bottom: 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detailProjectInfoMeta {
    font-size: .875rem;
    display: flex;
    justify-content: start;
    align-items: center;
    color: rgb(148 163 184);
    font-size: .75rem;
}

.detailProjectInfoDescription {
    font-size: .75rem;
    line-height: 1rem;
    margin-top: 1rem;
}


.detailProgressTitle {
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 0.6rem;
}


.detailPageRulesTitle{
    margin-top: 1rem;
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(148 163 184);
}

.detailPageRulesBody {
    margin-top: 1rem;
    background-color: rgb(46, 48, 58);
    padding: 0.8rem;
    font-size: .8rem;
    line-height: 1.5;
    color: rgb(148 163 184);
}

.detailPageRulesBodyWarning {
    display: flex;
    align-items: center;
}

.detailPageRulesBodyWarning img {
    margin-right: '1rem';
}

.recentlyLogs{
    margin-top: 1rem;
    background-color: rgb(46, 48, 58);
    padding: 0.8rem;
}

.recentlyLogsItem {
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: .85rem;
    line-height: 1.5;
    cursor: pointer;
    display: flex ;
    justify-content: space-between;
}

/* first span of recentlyLogsItem */
/* .recentlyLogsItem span{
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    color: rgb(148 163 184);
} */

.recentlyLogsItem:hover{
    text-decoration: underline;
}

.hightlight{
    color: #0dcaf0 !important;
    margin-bottom: 0.3rem;
}


@media (max-width: 768px) {
    .detailLarge {
        display: none;
    }
    .detailSmall {
        display: block;
    }
}

@media (min-width: 769px) {
    .detailLarge {
        display: block;
    }
    .detailSmall {
        display: none;
    }
}

.detailSmallContent {
    padding: 1rem;
}

.smallContent {
    font-size: 0.8rem;
    line-height:2;
}

.smallTitle {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.warningPanel {
    background-color: rgb(46, 48, 58);
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 10px;
    font-size: 0.7rem;
    line-height: 1.5;
}

.warningPanelRow {
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.smallInfomationRow {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.smallInformationLabel {
    color: rgb(148 163 184);
    font-size: 0.8rem;
    width: 8rem;
}

.smallInfomationRow img {
    width: 1rem;
    height: 1rem;
}
.smallInformationValue {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    font-size: 0.7rem;
}