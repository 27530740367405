.projectCard {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0.2rem;
    /* border-radius: 0.5rem; */
    background-color: var(--color-white);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
}

/* projectCard hover */
.projectCard:hover {
    border: 1px solid rgba(var(--bs-warning-rgb));
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.projectCardInfo {
    display: flex;
    flex-direction: column;
    width: 70%;
    text-align: left;
    margin-left: 1rem;
    font-size: 0.875rem;
}

.projectCardTitle {
    /* color: rgb(191 219 254) */
    color: #1BB2DA;
    display: flex;
    align-content: center;
}

.hightlight{
    color: #1BB2DA;
}

.detail2info1 > div:first-child {
    color: #F59A23;
    font-weight: bolder;
}


.z1 {
    font-size: 2rem;  line-height: 2.75rem;
}

.z2 {
    font-size: 1.25rem;  line-height: 1.75rem;
}

.z3 {
    font-size: .875rem;  line-height: 1.25rem;   
}

.z4 {
    font-size: .75rem;    line-height: 1rem;
}

.bold {
    font-weight: bold;
}

@media (max-width: 768px) {
    .only-pc {
        display: none;
    }

    .only-mobile {
        display: block;
    }

    .mobile-margin-top {
        margin-top: 1rem;
    }
}

@media (min-width: 769px) {
    .only-pc {
        display: block;
    }

    .only-mobile {
        display: none;
    }
}