.trade-buttons{
    display: flex;
    justify-content: space-between;
}

.trade-buttons button{
    width: 50%;
}

.secondBtn {
    background-color: rgb(27,29,40);
    color: #999;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 0.2rem 0.6rem;
    margin-top: 1rem;
}

.trade-form{
    margin-top: 1rem;
}

.trade-buttons-inline {
    display: flex;
    gap: 0.4rem;
}

.quoteDisplay {
    font-size: 0.75rem;
    color: #999;
}

.slippageOptions{
    display: flex;
    justify-content: start;
    margin-top: 1rem;
    gap: 0.6rem;
}
.slippageOption {

    background-color: rgb(27,29,40);
    color: #999;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 0.4rem 1rem;
}

.slippageOptionActive {
    background-color: #0dcaf0;
    color: #fff;
}

.connectCover{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 100;
    border-radius: 0.5rem;
}