.benefits-widget-cover {
    width: 50%;
}
.benefits-widget-content{
    display: flex;
    gap: 1rem;
    justify-content: start;
}
.benefits-widget-title{
    margin-top: 1rem;
}
.lpItem img {
    width: 100%;
    border-radius: 5px;
    margin-right: 1.6rem;
}
a{
    text-decoration: none;
}

.lpItem{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}
.lpItem .lpItemInfo{
    display: flex;
    justify-content: start;
    width: 70%;
    text-align: left;
    font-size: 0.8rem;
}

.lpItemInfo{
    word-break: break-all;
}